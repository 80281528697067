._3Qwdw {
  background: white;
  display: flex;
  color: gray;
  margin-top: 12px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
}

._3Qwdw h1 { display: none; }

._35ZU7 {
  font-size: 15px;
  font-weight: 400;
  color: #676767;
}
._35ZU7 {
  padding: 12px;
}

._1pZPu {
  align-items: center;
  justify-content: center;
  padding: 0 16px 0 8px;
  display: flex;
  cursor: pointer;
}
._1pZPu:hover {
  opacity: .7;
}
._1pZPu svg {
  transform: scale(1.1);
}
._1pZPu svg g use {
  fill: #BBBBBB;
}

._3ukcU {
  display: flex;
  background-color: #1ca391;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 8px;
  color: white;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}


._1FeIx {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 0 24px;
  color: #0280af;
}

._1FeIx:hover {
  cursor: pointer;
  color: #0b74a3;
  text-decoration: none;
  opacity: .7;
}

._q4m7O {
  position: fixed;
  top: 0;
  right: 0;

  padding-right: 16px;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  z-index: 10001;
}


._2ciAg {
  display: flex;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

._lsG6i {
  position: absolute;
  right: 8px;
  top: 8px;
}


._DHl6B {
  background: #00a690;
  color: white;
  border-radius: 12px;
  display: inline-block;
  padding: 3px 6px;
  font-size: 10px;
  font-weight: 400;
  margin-left: 4px;
}

._3-d0k {
  bottom: 40px;
  right: 40px;
  height: 70px;
  width: 70px;
  text-transform: uppercase;
  background: #292e33;
  box-shadow: 0 0 4px 2px rgba(0,0,0,0.2);
  font-size: 14px;
  color: white;
  border-radius: 30px 30px 4px 30px;
  font-weight: 500;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50000;
}

._3-d0k svg {
  margin-top: -4px;
  margin-right: -4px;
}

._3-d0k svg path:last-child {
  fill: white;
}

._3-d0k:hover {
  opacity: .7;
  transition: all .25s ease-in-out;
  cursor: pointer;
}

@keyframes _2DVLg {
  0%   { opacity:.8; }
  50%  { opacity:.4; }
  100% { opacity:.8; }
}
._3oWBp {
  position: absolute;
  bottom: 6px;
  right: 6px;
  width: 8px;
  height: 8px;
  animation: _2DVLg 1s infinite;
  background: #00ff9f;
  border-radius: 50%;
}


._1ZAe3 {
  opacity:0;
  visibility: hidden;
  position: fixed;
  background: rgb(242, 242, 242);
  height: 100vh;
  width: 420px;

  box-shadow: 0 0 8px 2px rgba(0,0,0,0.05);
  border-left: 1px solid rgba(0,0,0,0.05);
  z-index: 50000;
}
._3pp3Q {
  opacity:1;
  visibility: visible;
}

._3PpO2 {
  color: var(--cc-main);
}

._3PpO2:hover {

  color: var(--color-link);
}

._3PpO2 svg path {
  stroke: var(--cc-main);
}

._3PpO2 svg {
  transform: scale(0.7);
  margin-bottom: 2px;
}

._1rsLv {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

}

._2rbq4 svg:first-child {
  cursor: pointer;
}

._2rbq4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  background: #292e33;
}

._1i9d2 > a._1z34g,
._1i9d2 > a {
  color: var(--color-main);
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
}

._2Wygd img {
  display: none;
  visibility: hidden;
  opacity: 0;
}

._2Wygd {
  flex-direction: column;
  width: 100%;
  font-size: 18px;
  color: #969696;
  font-weight: 300;
  text-align: center;
  display: flex;
  align-items: center;
  background: white;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  flex: 0;
  transition: opacity .3s ease-in-out;
}

._12Wdw img {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: opacity .5s ease-in-out;
}

._12Wdw {
  padding: 50px 0 50px 0;
  visibility: visible;
  flex: 1;
  opacity: 1;
}

._2Wygd span:first-child {
  line-height: 2;
  color: var(--bg-dark);
  font-weight: 500;
  font-size: 20px;
  margin-bottom:16px;
  max-width: 300px;
  height: 70px;
}

._2Wygd a {
  font-size: 14px;
}

._2Wygd > span:nth-child(2) {
  line-height: 2;
  margin-bottom:16px;
  font-size: 15px;
}

._2Wygd img {
  margin-top: 24px;
  width: 250px;
  margin-bottom: 32px;
}

._1i9d2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px 12px 20px;
  background: #ebebeb;

  border-bottom: 1px solid #e0e0e0;
}

._2_Qr5 svg g {
  transform: scale(1.2);
}
._2_Qr5 svg {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

._2_Qr5 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

._3Oom5 {
  height: 40px;
  padding: 0 12px;
  border-left: none;
  min-height: 40px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

._3Oom5 input {
  border-radius: 0;
}

._o5GRf {
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
}
._o5GRf svg path {
  transform: scale(.59);
}
._o5GRf svg {
  margin-right: 4px;
  width: 32px;
  height: 32px;
}

._o5GRf svg._2Zgh7 {
  -ms-transform: scale(0.59,0.59);
  margin-left: -8px;
  margin-top: -13px;
  margin-right: 14px;
  padding: 4px 0;
}

._1HcYg {
  border-bottom: 2px solid white;
  padding: 4px 7px 4px 7px;
  cursor: pointer;
  width: 2px;
  margin-right: 8px;
}

/* add css module styles here (optional) */
:root {
  --color-sec: #1CA391;
  --color-main: #0E8FC9;
  --color-link: #0B74A3;
  --color-main-dark: #2A5A70;
  --bg-dark: #373b40;
  --bg-darker: #292e33;
  --ck-image-style-spacing: 1.5em;
  --ck-highlight-marker-blue: #72cdfd;
  --ck-highlight-marker-green: #63f963;
  --ck-highlight-marker-pink: #fc7999;
  --ck-highlight-marker-yellow: #fdfd77;
  --ck-highlight-pen-green: #118800;
  --ck-highlight-pen-red: #e91313;
  --ck-image-style-spacing: 1.5em;
  --ck-todo-list-checkmark-size: 16px;

  --cc-main: #0e8fc9;
  --cc-bg-light: #fafafa;
  --cc-separator: #cfcfcf;
  --cc-text-dark: #525252;
  --cc-text-darker: #383838;
}


body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  text-decoration: none;
  vertical-align: middle;
  transition: none;
  word-wrap: break-word;
  font-family: Roboto, sans-serif;
}
